* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

ol,
ul {
  list-style-position: inside;
}

blockquote {
  background-color: var(--blockquote-background-color);
  padding: var(--spacing-base);
  border-left: var(--spacing-2xs) solid var(--color-grey-400);
}

a {
  color: var(--link-color);
  text-decoration: none;
}