.ql-container {
  min-height: var(--spacing-5xl);
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.ql-container > .ql-editor.ql-blank::before {
  color: var(--placeholder-color);
}

.ql-toolbar {
  border-top-left-radius: var(--spacing-2xs);
  border-top-right-radius: var(--spacing-2xs);
}

.ql-container {
  border-bottom-left-radius: var(--spacing-2xs);
  border-bottom-right-radius: var(--spacing-2xs);
}

.ql-toolbar .ql-formats .ql-stroke {
  fill: none;
  stroke: var(--header-icon-color);
}

.ql-toolbar .ql-formats .ql-fill {
  fill: var(--header-icon-color);
  stroke: none;
}

.ql-editor blockquote {
  background-color: var(--blockquote-background-color);
  padding: var(--spacing-base);
  border-left: var(--spacing-2xs) solid var(--color-grey-400);
}

.ql-editor a {
  color: var(--link-color);
  text-decoration: none !important;
}
